<template>
  <div>
    <div>
      <h2>
        <span v-if="$route.name === 'filterPrivateLessonsByUser'">
          <span v-if="$route.params.roleType === 'student'">
            {{ filterStudentFirstName }}
          </span>
          <span v-else-if="$route.params.roleType === 'teacher'">
            {{ filterTeacherFirstName }}
          </span>
        </span>
        Lessons
      </h2>
      <hr />
      <div class="searchBar">
        <el-input
          ref="filterInput"
          v-model="keyword"
          placeholder="Please enter name"
        />
        <el-button size="small" type="success" @click="searchByKeyWord">
          {{ $t("button.search") }}
        </el-button>
      </div>
      <div>
        <el-button
          size="small"
          :type="isEnded ? 'default' : 'success'"
          @click="fetchOngoingLesson"
        >
          {{ $t("courses.ongoing") }}
        </el-button>
        <el-button
          size="small"
          :type="!isEnded ? 'default' : 'success'"
          @click="fetchInactiveLesson"
        >
          {{ $t("courses.inactive") }}
        </el-button>
      </div>
    </div>
    <el-table
      :data="privateLessonsList"
      :row-class-name="tableRowClassName"
      style="width: 100%; font-size: 14px;margin:20px 0"
    >
      <el-table-column label="#" prop="id" width="80" />
      <el-table-column width="40px">
        <template slot-scope="scope">
          <div
            key="user"
            v-if="scope.row.active_session_class_teachers.length === 1"
          >
            <i class="fas fa-user" />
          </div>
          <div key="users" v-else>
            <i class="fas fa-users" />
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.teacher')">
        <template slot-scope="scope">
          <div
            :key="teacher.teacher_user.id"
            v-for="teacher in scope.row.active_session_class_teachers"
            class="userList"
          >
            <router-link
              :to="{
                name: 'filterPrivateLessonsByUser',
                params: {
                  roleType: 'teacher',
                  userId: teacher.teacher_user.id
                }
              }"
            >
              {{
                `${userMethods.displayName(
                  teacher.teacher_user.first_name,
                  teacher.teacher_user.last_name
                )}`
              }}
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="40px">
        <template slot-scope="scope">
          <div key="user" v-if="scope.row.student_class_tickets.length === 1">
            <i class="fas fa-user" />
          </div>
          <div key="users" v-else>
            <i class="fas fa-users" />
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.student')">
        <template slot-scope="scope">
          <div
            :key="student.student_user_id"
            v-for="student in scope.row.student_class_tickets"
            class="userList"
          >
            <router-link
              :to="{
                name: 'filterPrivateLessonsByUser',
                params: {
                  roleType: 'student',
                  userId: student.student_user_id
                }
              }"
            >
              {{ `${userMethods.displayName(student.first_name, student.last_name)}` }}
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="40px">
        <template slot-scope="scope">
          <IsOnlineIcon :isOnline="scope.row.is_online" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.subject')">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'privateClassesDetail',
              params: {
                id: scope.row.id
              }
            }"
          >
            {{ scope.row.title }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        width="140px"
        v-if="$store.getters['user/isRole']('super-admin')"
        :label="$t('column.tuition')"
      >
        <template slot-scope="scope">
          <div
            :key="student.student_user_id"
            v-for="student in scope.row.student_class_tickets"
            class="feeList"
          >
            <span>
              {{
                addCurrencySymbol(
                  Number(scope.row.is_online ? student.online_fee : student.fee),
                  scope.row.is_online ? student.online_fee_currency : student.fee_currency
                )
              }}/hr
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="140px"
        v-if="$store.getters['user/isRole']('super-admin')"
        :label="$t('column.salary')"
      >
        <template slot-scope="scope">
          <div
            :key="teacher.teacher_user.id"
            v-for="teacher in scope.row.active_session_class_teachers"
            class="feeList"
          >
            <span>
              {{
                addCurrencySymbol(
                  Number(scope.row.is_online ? teacher.online_salary : teacher.salary),
                  scope.row.is_online ? teacher.online_salary_currency : teacher.salary_currency
                )
              }}/hr
            </span>
          </div>
          <span>
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.createdOn')">
        <template slot-scope="scope">
          <span v-if="!scope.row.created_at">-</span>
          <span v-else>
            {{ instant.formatGoogleTime(scope.row.created_at) || "-" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.lastLesson')">
        <template slot-scope="scope">
          <router-link
            v-if="isExistStudentClassTickets(scope.row.student_class_tickets)"
            :to="{
              name: 'privateLessonsTeachingLogDetail',
              params: {
                privateType: 'private-lessons',
                courseId: scope.row.id,
                teachingLogId:
                  scope.row.student_class_tickets[0].last_lesson_log
                    .teaching_log_id
              }
            }"
          >
            {{
              instant.formatGoogleTime(
                scope.row.student_class_tickets[0].last_lesson_log.started_at
              )
            }}
          </router-link>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.lessons')" width="100">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'counselingClassesDetail',
              params: {
                id: scope.row.id
              }
            }"
          >
            {{ getLessonLogsCount(scope.row.student_class_tickets) || "-" }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column v-if="isEnded" label="結束原因">
        <template slot-scope="scope">
          <EndReasonViewer :endReason="scope.row.end_reason || ''" />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="Add a lesson log"
            placement="top"
            popper-class="tooltipColor"
            v-if="
              isEnded === 0 &&
                ($store.getters['user/isRole']('super-admin') ||
                  $store.getters['user/isRole']('teacher'))
            "
          >
            <router-link
              :to="{
                name: 'privateClassesCreateLog',
                params: {
                  privateType: 'private-lessons',
                  id: scope.row.id
                }
              }"
            >
              <i class="el-icon-plus" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Add a progress report"
            placement="top"
            popper-class="tooltipColor"
            v-if="
              isEnded === 0 &&
                ($store.getters['user/isRole']('super-admin') ||
                  $store.getters['user/isRole']('teacher'))
            "
          >
            <router-link
              :to="{
                name: 'privateClassesCreateReport',
                params: {
                  privateType: 'private-lessons',
                  classId: scope.row.id
                }
              }"
            >
              <i class="el-icon-document" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Details"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: 'privateClassesDetail',
                params: {
                  id: scope.row.id
                }
              }"
            >
              <i class="el-icon-view" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Edit"
            placement="top"
            popper-class="tooltipColor"
            v-if="$store.getters['user/isRole']('super-admin')"
          >
            <router-link
              :to="{
                name: 'privateClassesEdit',
                params: {
                  privateType: 'private-lessons',
                  class_id: scope.row.id
                }
              }"
            >
              <i class="el-icon-edit-outline" />
            </router-link>
          </el-tooltip>
          <template
            v-if="
              $store.getters['user/isRole']('super-admin') ||
                $store.getters['user/isRole']('teacher')
            "
          >
            <el-tooltip
              key="inactive"
              class="item"
              content="End"
              placement="top"
              popper-class="tooltipColor"
              v-if="isEnded === 0"
            >
              <span @click="setEndLessonId(scope.row.id)">
                <i class="far fa-stop-circle action-icon" />
              </span>
            </el-tooltip>
            <el-tooltip
              key="active"
              class="item"
              content="Start"
              placement="top"
              popper-class="tooltipColor"
              v-else
            >
              <span @click="restartCustomLesson(scope.row.id)">
                <i class="far fa-play-circle action-icon" />
              </span>
            </el-tooltip>
          </template>
          <el-tooltip
            class="item"
            content="Delete"
            placement="top"
            popper-class="tooltipColor"
            v-if="$store.getters['user/isRole']('super-admin')"
          >
            <i
              class="el-icon-delete action-icon warning-icon"
              @click="handleDelete(scope.row.id)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="filterLessonsCondition.page"
        @current-change="changeLessonsPage"
        :total="privateLessonsListCount"
      >
      </el-pagination>
    </div>
    <EndLessonReasonDialog
      :visible="Boolean(handleEndClassId)"
      :classId="handleEndClassId"
      @onCancel="clearEndClassId"
      @onSubmitted="() => {
        clearEndClassId();
        loadPrivateLessons();
      }"
    />
  </div>
</template>

<script>
import moment from "moment";
import { user, instant } from "@ivy-way/material";
import IsOnlineIcon from "@/components/icon/IsOnline";
import EndReasonViewer from "@/components/privateLesson/EndReasonViewer";
import EndLessonReasonDialog from "@/views/private/EndLessonReasonDialog";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import courseApi from "@/apis/course";
import enrollmentApi from "@/apis/enrollment";

export default {
  async created() {
    if (this.$route.query.lessonsPage) {
      this.filterLessonsCondition = {
        page: Number(this.$route.query.lessonsPage)
      };
    }

    if (this.$route.query.isEnded) {
      this.isEnded = Number(this.$route.query.isEnded);
    }
    
    await this.loadPrivateLessons();
  },
  mounted() {
    this.$refs.filterInput.$el.addEventListener("keydown", e => {
      if (e.code === "Enter") this.searchByKeyWord();
    });
  },
  components: { IsOnlineIcon, EndLessonReasonDialog, EndReasonViewer },
  mixins: [roleMixin, moneyMixin],
  data() {
    return {
      pageSize: 30,
      currentPage: 1,
      privateLessonsList: [],
      privateLessonsListCount: 0,
      isEnded: 0,
      keyword: "",
      handleEndClassId: null,
      filterLessonsCondition: {
        page: 1
      }
    };
  },
  computed: {
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    pageTitle() {
      return this.$t(`pageTitle.${this.$route.name}`);
    },
    filterStudentFirstName() {
      if (this.privateLessonsList.length > 0) {
        return (this.privateLessonsList[0]?.student_class_tickets || []).find(
          ({ student_user_id }) => student_user_id === Number(this.$route.params.userId)
        ).first_name + "'s ";
      } else {
        return "";
      }
    },
    filterTeacherFirstName() {
      if (this.privateLessonsList.length > 0) {
        return (this.privateLessonsList[0]?.active_session_class_teachers || []).find(
          ({ teacher_user_id }) => teacher_user_id === Number(this.$route.params.userId)
        ).teacher_user.first_name + "'s ";
      } else {
        return "";
      }
    }
  },
  watch: {},
  methods: {
    moment() {
      return moment();
    },
    isExistStudentClassTickets(studentClassTickets) {
      return studentClassTickets[0]?.last_lesson_log;
    },
    getLessonLogsCount(studentClassTickets) {
      return studentClassTickets[0]?.lesson_logs_cnt;
    },
    searchByKeyWord() {
      this.filterLessonsCondition.page = 1;
      this.loadPrivateLessons();
    },
    async loadPrivateLessons() {
      let pairedQueryParams = {
        look_for_teacher: 0,
        is_ended: this.isEnded,
        keyword: this.keyword,
        page_size: this.pageSize,
        page: this.filterLessonsCondition.page,
        course_session_type: "tutoring",
        ...this.filterCustomLessonByUserId()
      };
      let {
        session_classes: sessionClasses
      } = await enrollmentApi.fetchPrivateLessons(pairedQueryParams);
      this.privateLessonsList = sessionClasses.data
        .filter(sessionClass => sessionClass.id)
        .map(sessionClass => ({
          ...sessionClass,
          student_class_tickets: sessionClass.student_class_tickets || [],
          active_session_class_teachers: sessionClass.active_session_class_teachers || []
        }));
      if (this.isRoleTeacher() && !this.isRoleAdmin()) {
        this.privateLessonsList = this.privateLessonsList.map(sessionClass => ({
          ...sessionClass,
          active_session_class_teachers: [
            {
              teacher_user: {
                first_name: this.$store.getters["user/getProfile"].first_name,
                last_name: this.$store.getters["user/getProfile"].last_name,
                id: this.$store.getters["user/getProfile"].id
              }
            }
          ]
        }));
      }
      this.privateLessonsListCount = sessionClasses.total;
    },

    async fetchOngoingLesson() {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, isEnded: 0 }
      });
    },
    async fetchInactiveLesson() {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, isEnded: 1 }
      });
    },
    filterCustomLessonByUserId() {
      if (this.$route.name === "filterPrivateLessonsByUser") {
        const filterParams = {
          [this.$route.params.roleType === "teacher" ? "teacher_user_id" : "student_user_id"]: this.$route.params.userId
        };
        return filterParams;
      } else {
        return {};
      }
    },
    setEndLessonId(id) {
      this.handleEndClassId = id;
    },
    clearEndClassId() {
      this.handleEndClassId = null;
    },
    async restartCustomLesson(id) {
      try {
        await courseApi.restartSessionClass(id);
        await this.loadPrivateLessons();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async handleDelete(id) {
      await this.$emit("handleDelete", {
        id,
        callback: this.loadPrivateLessons
      });
    },
    tableRowClassName({ row }) {
      if ((row.student_class_tickets || []).length > 1 ||
      (row.active_session_class_teachers || []).length > 1) {
        return "multipleStudentsOrTeachers";
      }
      return "";
    },
    changeLessonsPage(page) {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...(this.$route.params || {})
        },
        query: {
          ...(this.$route.query || {}),
          lessonsPage: page
        }
      });
    }
  }
};
</script>

<style scoped>
.shortcutBtns > a {
  margin-right: 8px;
}

.container-fluid {
  padding: 20px;
  max-width: 95vw;
}
.item {
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}

::v-deep .multipleStudentsOrTeachers {
  background: rgb(245, 247, 250);
}

.searchBar {
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-column-gap: 8px;
  margin-bottom: 12px;
}

.feeList:not(:first-child),
.userList:not(:first-child) {
  border-top: 1px solid #EBEEF5;
  padding-top: 4px;
  display: block;
}

.feeList:not(:last-child),
.userList:not(:last-child) {
  padding-bottom: 8px;
}
</style>
