var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('h2',[(_vm.isFilterByUser)?_c('span',[(_vm.$route.params.roleType === 'student')?_c('span',[_vm._v(" "+_vm._s(_vm.filterStudentFirstName)+" ")]):(_vm.$route.params.roleType === 'teacher')?_c('span',[_vm._v(" "+_vm._s(_vm.filterTeacherFirstName)+" ")]):_vm._e()]):_vm._e(),_vm._v(" Lesson Requests "),(_vm.isRoleAdmin())?_c('el-tooltip',{attrs:{"enterable":false,"content":"新增課程班級","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":"/create-class/how-to-billing"}},[_c('i',{staticClass:"fas fa-plus"})])],1):_vm._e()],1)]),_c('hr'),(!_vm.isRoleAdmin() && _vm.isRoleTeacher())?_c('div',[_c('el-button',{attrs:{"size":"small","type":_vm.hasReplied ? 'default' : 'success'},on:{"click":_vm.setHasReplied}},[_vm._v(" 未回覆 ")]),_c('el-button',{attrs:{"size":"small","type":!_vm.hasReplied ? 'default' : 'success'},on:{"click":_vm.setHasReplied}},[_vm._v(" 已回覆 ")])],1):_vm._e(),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.privateLessonRequestsList,"row-class-name":_vm.tableRowClassName}},[[_c('el-table-column',{attrs:{"type":"index","width":"50"}}),_c('el-table-column',{attrs:{"width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('IsOnlineIcon',{attrs:{"isOnline":scope.row.is_online}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.subject')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isRoleAdmin())?_c('router-link',{attrs:{"to":{
                name: _vm.getClassDetailLink(scope.row.course_session_id),
                params: { 
                  id: scope.row.classId,
                  class_id: scope.row.id ? scope.row.id : null,
                }
            }}},[_vm._v(" "+_vm._s(scope.row.subject)+" ")]):_c('span',[_vm._v(_vm._s(scope.row.subject))])]}}])}),_c('el-table-column',{attrs:{"width":"120px","label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getPrivateTypeName(scope.row.student_class_tickets[0]))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.student_class_tickets.length === 1)?_c('div',[_c('i',{staticClass:"fas fa-user"})]):_c('div',[_c('i',{staticClass:"fas fa-users"})])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.student')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.student_class_tickets),function(studentClassTicket){return _c('div',{key:studentClassTicket.id},[_c('router-link',{attrs:{"to":{
                name: 'ProfileForAdmin',
                params: { id: studentClassTicket.student_user_id }
              }}},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName( studentClassTicket.first_name, studentClassTicket.last_name )}`)+" ")])],1)})}}])})],(_vm.isRoleAdmin())?[_c('el-table-column',{attrs:{"label":_vm.$t('column.teachersInvited'),"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.class_teacher_invitations.length < 4)?_c('div',_vm._l((scope.row.class_teacher_invitations),function(invitation,idx){return _c('span',{key:idx},[_c('router-link',{attrs:{"to":{
                  name: 'ProfileForAdmin',
                  params: { id: invitation.teacher_user.id }
                }}},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName( invitation.teacher_user.first_name, invitation.teacher_user.last_name )}`)),(idx < scope.row.class_teacher_invitations.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])],1)}),0):_c('div',[_vm._l((scope.row
                .class_teacher_invitations),function(invitation,index){return _c('span',{key:index},[(index < 3)?_c('span',[_c('router-link',{attrs:{"to":{
                    name: 'ProfileForAdmin',
                    params: { id: invitation.teacher_user.id }
                  }}},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName( invitation.teacher_user.first_name, invitation.teacher_user.last_name )}`))]),(index < 2)?_c('span',[_vm._v(",")]):_vm._e()],1):_vm._e()])}),(scope.row.class_teacher_invitations.length > 3)?_c('span',[_vm._v(" and "+_vm._s(scope.row.class_teacher_invitations.length - 3)+" others ")]):_vm._e()],2)]}}],null,false,2458974248)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.noReply')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.no_reply_cnt > 0)?_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":scope.row.no_reply_teachers.join(', '),"placement":"top"}},[_c('el-link',[_vm._v(_vm._s(scope.row.no_reply_cnt))])],1)],1):_c('div',[_vm._v("0")])]}}],null,false,2815046702)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.accepted')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.accept_cnt > 0)?_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":scope.row.accept_teachers.join(', '),"placement":"top"}},[_c('el-link',[_vm._v(_vm._s(scope.row.accept_cnt))])],1)],1):_c('div',[_vm._v("0")])]}}],null,false,2319679298)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.rejected')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.reject_cnt > 0)?_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":scope.row.reject_teachers.join(', '),"placement":"top"}},[_c('el-link',[_vm._v(_vm._s(scope.row.reject_cnt))])],1)],1):_c('div',[_vm._v("0")])]}}],null,false,2093566381)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.action'),"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"content":"Send invites to teachers","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
                name: 'privateLessonInvitation',
                params: { id: scope.row.classId }
              }}},[_c('i',{staticClass:"fas fa-user-plus action-icon"})])],1),_c('el-tooltip',{staticClass:"item",attrs:{"content":"Details","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
                name: _vm.getClassDetailLink(scope.row.course_session.id),
                params: { 
                  id: scope.row.classId, 
                  class_id: scope.row.id
                }
              }}},[_c('i',{staticClass:"el-icon-view action-icon"})])],1),(_vm.isRoleAdmin())?_c('el-tooltip',{staticClass:"item",attrs:{"content":"Edit","placement":"top","popper-class":"tooltipColor"}},[(scope.row.course_session_type <= 2 )?_c('router-link',{attrs:{"to":{
                name: 'privateClassesEdit',
                params: {
                  privateType: 'private-lessons',
                  class_id: scope.row.id
                }
              }}},[_c('i',{staticClass:"el-icon-edit-outline action-icon"})]):(scope.row.course_session_type >= 3 &&  scope.row.course_session_type <= 6)?_c('router-link',{attrs:{"to":{
                name: 'privateClassesEdit',
                params: {
                  privateType: 'counseling',
                  class_id: scope.row.id
                }
              }}},[_c('i',{staticClass:"el-icon-edit-outline action-icon"})]):_c('router-link',{attrs:{"to":{
                name: 'sessionClassesEdit',
                params: { id: scope.row.id }
              }}},[_c('i',{staticClass:"el-icon-edit-outline action-icon"})])],1):_vm._e(),(_vm.isRoleAdmin())?_c('el-tooltip',{staticClass:"item",attrs:{"content":"Link student with a teacher","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
                name: 'privateLessonLinkTeacher',
                params: {
                  privateType: _vm.isPrivateLesson ? 'private-lessons' : 'counseling',
                  class_id: scope.row.classId
                }
              }}},[_c('i',{staticClass:"el-icon-connection action-icon"})])],1):_vm._e(),(_vm.isRoleAdmin())?_c('el-tooltip',{staticClass:"item",attrs:{"content":"Delete","placement":"top","popper-class":"tooltipColor"}},[_c('i',{staticClass:"el-icon-delete action-icon warning-icon",on:{"click":() => _vm.handleDelete(scope.row.id)}})]):_vm._e()]}}],null,false,666939640)})]:_vm._e(),(!_vm.isRoleAdmin() && _vm.isRoleTeacher())?[_c('el-table-column',{attrs:{"label":"Salary"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.addCurrencySymbol(scope.row.salary, scope.row.salaryCurrency))+" /hr")])]}}],null,false,2390532306)}),_c('el-table-column',{attrs:{"label":"請求日期"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.requestDate))])]}}],null,false,567328483)})]:_vm._e(),[_c('el-table-column',{attrs:{"label":_vm.$t('column.responseStatus')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isOwnerInvitation(scope.row))?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(scope.row.repliedStatus == 0),expression:"scope.row.repliedStatus == 0"}],staticStyle:{"color":"grey"}},[_c('i',{staticClass:"el-icon-question"}),_vm._v(_vm._s(_vm.$t("column.noReply"))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(scope.row.repliedStatus == 1),expression:"scope.row.repliedStatus == 1"}],staticStyle:{"color":"forestgreen"}},[_c('i',{staticClass:"el-icon-success"}),_vm._v(_vm._s(_vm.$t("column.accepted"))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(scope.row.repliedStatus == 2),expression:"scope.row.repliedStatus == 2"}],staticStyle:{"color":"indianred"}},[_c('i',{staticClass:"el-icon-error"}),_vm._v(_vm._s(_vm.$t("column.rejected"))+" ")])]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.editResponse'),"width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              _vm.isEditReplyMode(scope.row.id) || scope.row.repliedStatus === 0
            )?_c('div',[(_vm.isOwnerInvitation(scope.row))?_c('el-button',{attrs:{"disabled":scope.row.repliedStatus === 1,"type":"primary","size":"mini"},on:{"click":() => _vm.openTeacherAvailableDialog(scope.row)}},[_vm._v(" "+_vm._s(_vm.$t("column.accept"))+" ")]):_vm._e(),(_vm.isOwnerInvitation(scope.row))?_c('el-button',{attrs:{"disabled":scope.row.repliedStatus === 2,"type":"danger","size":"mini"},on:{"click":() => {
                  _vm.replyInvitation(scope.row.id, scope.row.encryptedId, 2);
                }}},[_vm._v(" "+_vm._s(_vm.$t("column.reject"))+" ")]):_vm._e(),(_vm.editReplyRows[scope.row.id])?_c('span',{on:{"click":() => {
                  _vm.closeEditReplyBtns(scope.row.id);
                }}},[_c('i',{staticClass:"el-icon-circle-close action-icon"})]):_vm._e()],1):_c('div',[(_vm.isOwnerInvitation(scope.row))?_c('i',{staticClass:"el-icon-edit-outline action-icon",on:{"click":() => {
                  _vm.openEditReplyBtns(scope.row);
                }}}):_vm._e()])]}}])})]],2),_c('div',{staticClass:"text-center"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.pageSize,"current-page":_vm.filterLessonRequestsCondition.page,"total":_vm.privateLessonRequestsListCount},on:{"current-change":_vm.changeLessonRequestsPage}})],1),_c('br'),(_vm.acceptClass)?_c('TeacherAvailableDialog',{attrs:{"visible":_vm.teacherAvailableDialogVisible,"classId":_vm.acceptClass.session_class.id},on:{"onSubmit":repliedNote =>
        _vm.replyInvitation(
          _vm.acceptClass.id,
          _vm.acceptClass.encryptedId,
          1,
          repliedNote
        )}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }