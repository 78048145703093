var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('h2',[(_vm.$route.name === 'filterPrivateLessonsByUser')?_c('span',[(_vm.$route.params.roleType === 'student')?_c('span',[_vm._v(" "+_vm._s(_vm.filterStudentFirstName)+" ")]):(_vm.$route.params.roleType === 'teacher')?_c('span',[_vm._v(" "+_vm._s(_vm.filterTeacherFirstName)+" ")]):_vm._e()]):_vm._e(),_vm._v(" Lessons ")]),_c('hr'),_c('div',{staticClass:"searchBar"},[_c('el-input',{ref:"filterInput",attrs:{"placeholder":"Please enter name"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":_vm.searchByKeyWord}},[_vm._v(" "+_vm._s(_vm.$t("button.search"))+" ")])],1),_c('div',[_c('el-button',{attrs:{"size":"small","type":_vm.isEnded ? 'default' : 'success'},on:{"click":_vm.fetchOngoingLesson}},[_vm._v(" "+_vm._s(_vm.$t("courses.ongoing"))+" ")]),_c('el-button',{attrs:{"size":"small","type":!_vm.isEnded ? 'default' : 'success'},on:{"click":_vm.fetchInactiveLesson}},[_vm._v(" "+_vm._s(_vm.$t("courses.inactive"))+" ")])],1)]),_c('el-table',{staticStyle:{"width":"100%","font-size":"14px","margin":"20px 0"},attrs:{"data":_vm.privateLessonsList,"row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"label":"#","prop":"id","width":"80"}}),_c('el-table-column',{attrs:{"width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.active_session_class_teachers.length === 1)?_c('div',{key:"user"},[_c('i',{staticClass:"fas fa-user"})]):_c('div',{key:"users"},[_c('i',{staticClass:"fas fa-users"})])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.teacher')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.active_session_class_teachers),function(teacher){return _c('div',{key:teacher.teacher_user.id,staticClass:"userList"},[_c('router-link',{attrs:{"to":{
              name: 'filterPrivateLessonsByUser',
              params: {
                roleType: 'teacher',
                userId: teacher.teacher_user.id
              }
            }}},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName( teacher.teacher_user.first_name, teacher.teacher_user.last_name )}`)+" ")])],1)})}}])}),_c('el-table-column',{attrs:{"width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.student_class_tickets.length === 1)?_c('div',{key:"user"},[_c('i',{staticClass:"fas fa-user"})]):_c('div',{key:"users"},[_c('i',{staticClass:"fas fa-users"})])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.student')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.student_class_tickets),function(student){return _c('div',{key:student.student_user_id,staticClass:"userList"},[_c('router-link',{attrs:{"to":{
              name: 'filterPrivateLessonsByUser',
              params: {
                roleType: 'student',
                userId: student.student_user_id
              }
            }}},[_vm._v(" "+_vm._s(`${_vm.userMethods.displayName(student.first_name, student.last_name)}`)+" ")])],1)})}}])}),_c('el-table-column',{attrs:{"width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('IsOnlineIcon',{attrs:{"isOnline":scope.row.is_online}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.subject')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'privateClassesDetail',
            params: {
              id: scope.row.id
            }
          }}},[_vm._v(" "+_vm._s(scope.row.title)+" ")])]}}])}),(_vm.$store.getters['user/isRole']('super-admin'))?_c('el-table-column',{attrs:{"width":"140px","label":_vm.$t('column.tuition')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.student_class_tickets),function(student){return _c('div',{key:student.student_user_id,staticClass:"feeList"},[_c('span',[_vm._v(" "+_vm._s(_vm.addCurrencySymbol( Number(scope.row.is_online ? student.online_fee : student.fee), scope.row.is_online ? student.online_fee_currency : student.fee_currency ))+"/hr ")])])})}}],null,false,1407691278)}):_vm._e(),(_vm.$store.getters['user/isRole']('super-admin'))?_c('el-table-column',{attrs:{"width":"140px","label":_vm.$t('column.salary')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._l((scope.row.active_session_class_teachers),function(teacher){return _c('div',{key:teacher.teacher_user.id,staticClass:"feeList"},[_c('span',[_vm._v(" "+_vm._s(_vm.addCurrencySymbol( Number(scope.row.is_online ? teacher.online_salary : teacher.salary), scope.row.is_online ? teacher.online_salary_currency : teacher.salary_currency ))+"/hr ")])])}),_c('span')]}}],null,false,1815866829)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('column.createdOn')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.created_at)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime(scope.row.created_at) || "-")+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.lastLesson')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isExistStudentClassTickets(scope.row.student_class_tickets))?_c('router-link',{attrs:{"to":{
            name: 'privateLessonsTeachingLogDetail',
            params: {
              privateType: 'private-lessons',
              courseId: scope.row.id,
              teachingLogId:
                scope.row.student_class_tickets[0].last_lesson_log
                  .teaching_log_id
            }
          }}},[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime( scope.row.student_class_tickets[0].last_lesson_log.started_at ))+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.lessons'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'counselingClassesDetail',
            params: {
              id: scope.row.id
            }
          }}},[_vm._v(" "+_vm._s(_vm.getLessonLogsCount(scope.row.student_class_tickets) || "-")+" ")])]}}])}),(_vm.isEnded)?_c('el-table-column',{attrs:{"label":"結束原因"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('EndReasonViewer',{attrs:{"endReason":scope.row.end_reason || ''}})]}}],null,false,4265471783)}):_vm._e(),_c('el-table-column',{attrs:{"label":"操作","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            _vm.isEnded === 0 &&
              (_vm.$store.getters['user/isRole']('super-admin') ||
                _vm.$store.getters['user/isRole']('teacher'))
          )?_c('el-tooltip',{staticClass:"item",attrs:{"content":"Add a lesson log","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
              name: 'privateClassesCreateLog',
              params: {
                privateType: 'private-lessons',
                id: scope.row.id
              }
            }}},[_c('i',{staticClass:"el-icon-plus"})])],1):_vm._e(),(
            _vm.isEnded === 0 &&
              (_vm.$store.getters['user/isRole']('super-admin') ||
                _vm.$store.getters['user/isRole']('teacher'))
          )?_c('el-tooltip',{staticClass:"item",attrs:{"content":"Add a progress report","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
              name: 'privateClassesCreateReport',
              params: {
                privateType: 'private-lessons',
                classId: scope.row.id
              }
            }}},[_c('i',{staticClass:"el-icon-document"})])],1):_vm._e(),_c('el-tooltip',{staticClass:"item",attrs:{"content":"Details","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
              name: 'privateClassesDetail',
              params: {
                id: scope.row.id
              }
            }}},[_c('i',{staticClass:"el-icon-view"})])],1),(_vm.$store.getters['user/isRole']('super-admin'))?_c('el-tooltip',{staticClass:"item",attrs:{"content":"Edit","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
              name: 'privateClassesEdit',
              params: {
                privateType: 'private-lessons',
                class_id: scope.row.id
              }
            }}},[_c('i',{staticClass:"el-icon-edit-outline"})])],1):_vm._e(),(
            _vm.$store.getters['user/isRole']('super-admin') ||
              _vm.$store.getters['user/isRole']('teacher')
          )?[(_vm.isEnded === 0)?_c('el-tooltip',{key:"inactive",staticClass:"item",attrs:{"content":"End","placement":"top","popper-class":"tooltipColor"}},[_c('span',{on:{"click":function($event){return _vm.setEndLessonId(scope.row.id)}}},[_c('i',{staticClass:"far fa-stop-circle action-icon"})])]):_c('el-tooltip',{key:"active",staticClass:"item",attrs:{"content":"Start","placement":"top","popper-class":"tooltipColor"}},[_c('span',{on:{"click":function($event){return _vm.restartCustomLesson(scope.row.id)}}},[_c('i',{staticClass:"far fa-play-circle action-icon"})])])]:_vm._e(),(_vm.$store.getters['user/isRole']('super-admin'))?_c('el-tooltip',{staticClass:"item",attrs:{"content":"Delete","placement":"top","popper-class":"tooltipColor"}},[_c('i',{staticClass:"el-icon-delete action-icon warning-icon",on:{"click":function($event){return _vm.handleDelete(scope.row.id)}}})]):_vm._e()]}}])})],1),_c('div',{staticClass:"text-center"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.pageSize,"current-page":_vm.filterLessonsCondition.page,"total":_vm.privateLessonsListCount},on:{"current-change":_vm.changeLessonsPage}})],1),_c('EndLessonReasonDialog',{attrs:{"visible":Boolean(_vm.handleEndClassId),"classId":_vm.handleEndClassId},on:{"onCancel":_vm.clearEndClassId,"onSubmitted":() => {
      _vm.clearEndClassId();
      _vm.loadPrivateLessons();
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }