<template>
  <div class="container-fluid container">
    <div v-if="isRoleAdmin()" class="shortcutBtns">
      <router-link :to="{ name: 'GroupCourse' }">
        <el-button>
          {{ $t("pageTitle.GroupCourse") }}
          <span v-if="$store.getters['user/isRole']('super-admin')">（按專案計費）</span>
        </el-button>
      </router-link>
      <router-link :to="{ name: 'privateLessons' }">
        <el-button :type="isPrivateLesson ? 'success' : ''">
          {{ $t("pageTitle.privateLessons") }}
          <span v-if="$store.getters['user/isRole']('super-admin')">（按鐘點費計費）</span>
        </el-button>
      </router-link>
      <router-link :to="{ name: 'counseling' }">
        <el-button :type="isCounseling ? 'success' : ''">
          {{ $t("pageTitle.counseling") }}
        </el-button>
      </router-link>
    </div>
    <CreateButton
      :isPrivateLesson="isPrivateLesson"
      v-if="$store.getters['user/isRole']('super-admin')"
    />
    <Breadcrumb />
    <h1>
      <span>
        {{ pageTitle }}
      </span>
    </h1>
    <div v-if="$store.getters['user/isRole']('super-admin')">
      <el-button plain type="success" @click="$router.push('/course-sessions')"
        >查看團體課梯次 <i class="fa fa-code-branch"></i>
      </el-button>
      <el-button plain type="success" @click="$router.push('/session-classes')"
        >查看團體課班級 <i class="fa fa-copyright"></i>
      </el-button>
    </div>
    <LessonRequests
      v-if="
        $store.getters['user/isRole']('super-admin') ||
          $store.getters['user/isRole']('teacher')
      "
      @handleDelete="handleDelete"
      :isPrivateLesson="isPrivateLesson"
    />
    <Counselings v-if="isCounseling" @handleDelete="handleDelete" />
    <PrivateLessons v-else-if="isPrivateLesson" @handleDelete="handleDelete" />
  </div>
</template>

<script>
import roleMixin from "@/mixins/role";
import Breadcrumb from "@/components/Breadcrumb";
import CreateButton from "@/views/private/CreateButton";
import LessonRequests from "@/views/private/LessonRequests";
import PrivateLessons from "@/views/private/PrivateLessons";
import Counselings from "@/views/private/Counselings";
import courseApi from "@/apis/course";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  mixins: [roleMixin],
  components: {
    Breadcrumb,
    CreateButton,
    LessonRequests,
    PrivateLessons,
    Counselings
  },
  computed: {
    pageTitle() {
      switch (this.$route.name) {
        case "filterPrivateLessonsByUser":
        case "privateLessons":
          return this.$t("pageTitle.privateLessons");
        case "filterCounselingByUser":
        case "counseling":
          return this.$t("pageTitle.counseling");
      }
      return "";
    },
    isCounseling() {
      return (
        this.$route.name === "counseling" ||
        this.$route.name === "filterCounselingByUser"
      );
    },
    isPrivateLesson() {
      return (
        this.$route.name === "privateLessons" ||
        this.$route.name === "filterPrivateLessonsByUser"
      );
    }
  },
  methods: {
    async handleDelete({ id, callback }) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await courseApi.deleteSessionClass(id);
        callback();
        this.$message({
          showClose: true,
          message: this.$t("message.delete_success"),
          type: "success"
        });
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      }
    }
  }
};
</script>

<style scoped>
.shortcutBtns > a {
  margin-right: 8px;
}

.container-fluid {
  padding: 20px;
  max-width: 95vw;
}
.item {
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}
</style>
