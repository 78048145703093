<template>
  <el-dialog
    class="dialog"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form header-align="left">
      <el-form-item require label="請輸入課程結束的原因：">
        <el-input type="textarea" rows="5" v-model="endReason" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('onCancel')">
        {{ $t("button.cancel") }}
      </el-button>
      <el-button type="primary" @click="onSubmit">
        {{ $t("button.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import courseApi from "@/apis/course";

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    classId: {
      default: null,
      type: Number
    }
  },
  data() {
    return {
      endReason: ""
    };
  },
  methods: {
    async onSubmit() {
      if (this.endReason.trim() === "") {
        this.$message.error("請輸入課程結束的原因！");
        return;
      }
      try {
        await courseApi.endSessionClass(this.classId, this.endReason);
        this.$emit("onSubmitted");
        this.endReason = "";
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  line-height: 26px;
  text-align: left;
}

@media (max-width: 992px) {
  ::v-deep .el-dialog {
    width: 90%;
  }
}

</style>
