<template>
  <div>
    <div class="d-flex justify-content-center">
      <el-tooltip
        class="item"
        :content="endReason.slice(0, 20)"
        placement="top"
        popper-class="tooltipColor"
      >
        <span
          v-if="endReason.length > 5"
          class="action-text readMore"
          @click="openEndReasonDialog"
        >
          <i class="fas fa-eye" />
        </span>
      </el-tooltip>
    </div>
    <el-dialog
      class="dialog"
      :visible="endReasonDialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
    >
      <h5>課程結束的原因：</h5>
      <p>{{ endReason }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEndReasonDialog">
          Close
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    endReason: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      endReasonDialogVisible: false
    };
  },
  methods: {
    openEndReasonDialog() {
      this.endReasonDialogVisible = true;
    },
    closeEndReasonDialog() {
      this.endReasonDialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.readMore {
  margin: 0px;
}
</style>
